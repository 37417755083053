<template>
    <b-card>
        <app-view-table :title="trans('client',5)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" v-on:onRowSelected="getProfile" :fields="fields" :canUserEdit="user_role < 3" :filterOptions="client_status_list" :isSearch="true" :isMenuColumn="false" :isFilterOptions="true" class="table-selectable">
            
        
        </app-view-table>  
    </b-card> 
</template>
<script>



import axios from "@axios";
import UserSettings from '@core/scripts/UserSettings';
import AppAlerts from '@core/scripts/AppAlerts';

export default {

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {      
                    locations: {type: 'list',  changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-250'},
                    statuses: {type: 'list', changed: 0, values: [], function: "getClientStatuses", parameters: null, widthClass:'app-width-min-250'},                  
                  
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0
            },
            fields: [
                { key: "client_name", label: this.trans('organization',169), thStyle: { "min-width": "90px", width: "100%" }, visible:true},
                { key: "location_name", label: this.trans('branch',182), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "locations"    },
                { key: "status_name", label: this.trans('status',3), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "statuses"  }, 
            ],
            items: [],
            userSettings: new UserSettings(),
            client_status_list: [
                { value: 1, name: this.trans('cl-clients-per-location',186) },
                { value: 2, name: this.trans('my-clients',3) },               
                { value: -1, name: ""},
                { value: 5, name: this.trans('cl-all-clients',186) },
            ],
            alertClass: new AppAlerts(),
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            
        };
    },

    methods: {
        getList: function() {
           this.loading_list = true;
           
           axios
                .post("clients/client/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

        getProfile: function(selectedRows) {
            if(selectedRows.length != 0){
                this.$router.push({ name: "clients-profile-organization", params: { id_client_data_main: selectedRows[0].id_client_data_main } });
            }
        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 2);
        },

        async loadSettings() {
            var getData = JSON.parse(localStorage.getItem('userSettings'));
            
            if(getData == undefined || getData.clientList == undefined){
                await this.userSettings.saveSettings(this.filters, 2);
                getData = JSON.parse(await localStorage.getItem('userSettings'));
            }
              
            var sFilters = getData.clientList

            this.filters.list_type = sFilters.list_type;
            this.filters.filtering = sFilters.filtering;
            this.filters.sorting = sFilters.sorting; 
            this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;        
        },

        onCreateThis() { 
            this.loadSettings();

            this.getList();

            this.$root.$on('locationsChanged', () => {
        
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });
        },

    },

 

 
};
</script>